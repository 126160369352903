import EventCircle from '@/assets/img/object_event_circle.png';
import EventCircleY from '@/assets/img/object_event_circle_y.png';
import ButtonMoreView from '@/components/ButtonMoreView';
import Container from '@/components/Container';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

interface EventProps {
  src: ImgHTMLAttributes<any>['src'];
  title: string;
  titleHash: string;
  subtext: string;
  link1: string;
  link2: string;
}

export const EventRecentList = styled.div`
  background: #1cabe2 url(${EventCircle}) no-repeat;
  background-position: top left 192px;
  background-size: auto 100%;
  padding-top: 118px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;

  ${breakpoint(`tablet`)} {
    background-position: top left;
    background-size: 52.78vw auto;
    margin: 0 -20px;
    width: auto;
    padding-top: 45.57vw;
    padding-bottom: 50px;
  }

  ${breakpoint(320)} {
    background-size: 169px auto;
    padding-top: 145px;
    padding-bottom: 50px;
  }

  &::before {
    content: '';
    display: block;
    width: 192px;
    height: 100%;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;

    ${breakpoint(`tablet`)} {
      display: none;
    }
  }
`;
export const EventImageWrap = styled.div`
  position: relative;
  width: 57.2%;

  ${breakpoint(`tablet`)} {
    width: 100%;
    padding-left: 20px;
    position: absolute;
    right: 0;
    top: -50px;
  }

  &::after {
    content: '';
    display: block;
    width: 144px;
    height: 144px;
    background: url(${EventCircleY}) center no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    bottom: 0;

    ${breakpoint(`tablet`)} {
      width: 18.75vw;
      height: 18.75vw;
    }
    ${breakpoint(320)} {
      width: 60px;
      height: 60px;
    }
  }
`;
export const EventImage = styled.img`
  max-width: 100%;
  width: 100%;
`;
export const EventTitleWrap = styled.div`
  width: 42.8%;
  padding-left: 64px;
  padding-top: 32px;
  color: #fff;

  ${breakpoint(1160)} {
    padding-left: 5.69vw;
    padding-top: 2.76vw;
  }
  ${breakpoint(`tablet`)} {
    width: 100%;
    padding: 0 0 0 45px;
  }

  ${Tit} {
    color: #fff;
    span {
      color: #ffdc0e;
    }

    &.t2 {
      margin-top: 16px;
    }

    ${breakpoint(`tablet`)} {
      display: block;
    }
  }

  ${ButtonMoreView} {
    margin-top: 48px;

    ${breakpoint(1160)} {
      margin-top: 4.14vw;
    }
    ${breakpoint(`tablet`)} {
      margin-top: 40px;
    }
  }
`;
const MainEventCard: FC<EventProps> = ({
  src,
  title,
  titleHash,
  subtext,
  link1,
  link2,
}) => (
  <>
    <Container>
      <EventRecentList>
        <EventImageWrap>
          <EventImage src={src} alt={title} title={subtext} />
        </EventImageWrap>
        <EventTitleWrap>
          <Tit size="s3" color="white">
            {title}
            {` `}
            <span
              css={`
                color: #ffdc0e;
              `}
            >
              {titleHash}
            </span>
          </Tit>
          <Tit size="s4" weight="normal" className="t2">
            {subtext}
          </Tit>
          <ButtonMoreView
            href={link1}
            target="_parent"
            mode="whiteTransparent"
            css={`
              font-size: 14px;
              ${breakpoint(425)} {
                min-width: 100px;
              }
            `}
          >
            후원하기
          </ButtonMoreView>
          <ButtonMoreView
            href={link2}
            target="_parent"
            mode="whiteTransparent"
            css={`
              font-size: 14px;
              margin-left: 10px;
              ${breakpoint(425)} {
                min-width: 100px;
              }
            `}
          >
            Donate
          </ButtonMoreView>
        </EventTitleWrap>
      </EventRecentList>
    </Container>
  </>
);
export default MainEventCard;
