import WhiteArrow from '@/assets/img/arrow_white_24x24@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

interface ImageProps {
  images: ImageLinkData[];
}
export interface ImageLinkData {
  id: string | number;
  href: string;
  src: ImgHTMLAttributes<any>['src'];
  title: string;
}

export const CardList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  margin-top: 177px;

  ${breakpoint(`tablet`)} {
    margin: 0 -20px;
    margin-top: 90px;
  }
`;
export const LinkLayout = styled.li`
  width: 33.33%;
  padding: 0 16px;

  ${breakpoint(`tablet`)} {
    width: 100%;
    padding: 0;
  }
`;
export const LinkImage = styled.a`
  display: block;
  width: 100%;
  padding-top: 56.82%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
export const LinkTitle = styled.span`
  color: #fff;
  display: inline-block;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 24px;
  font-size: 20px;
  font-weight: 700;

  ${breakpoint(1160)} {
    left: 2.59vw;
    right: 2.59vw;
  }

  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url(${WhiteArrow}) no-repeat center center;
    background-size: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  ${breakpoint(800)} {
    font-size: 18px;
  }
`;

const ImageLinkCard: FC<ImageProps> = ({ images }) => (
  <>
    <CardList>
      {images.map((image) => (
        <LinkLayout key={image.id}>
          <LinkImage
            href={image.href}
            css={`
              background-image: url(${image.src});
            `}
          >
            <LinkTitle>{image.title}</LinkTitle>
          </LinkImage>
        </LinkLayout>
      ))}
    </CardList>
  </>
);
export default ImageLinkCard;
