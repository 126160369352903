import LinkSafe from '../LinkSafe';
import React, {
  FC,
  useCallback,
  useEffect,
  ImgHTMLAttributes,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import {
  BOARD_CATEGORIES,
  BOARD_KIND_PRESS,
  BOARD_KIND_NEWS,
  BOARD_KIND_STORY,
  BOARD_KIND_PUBLIC,
  BOARD_KIND_INTERVIEW,
  BOARD_KIND_FIELDTRIP,
} from '@/helpers/BoardHelper';

interface NewsProps {
  keyword: string;
  isSwiper?: boolean;
  img?: boolean;
  video?: boolean;
}

export interface NewsData {
  id?: string | number;
  title?: string | undefined;
  link?: string;
  date?: string | number;
  boardCategoryCode?: string;
  category?: string;
}

const NewsList = styled.li`
  & > a {
    display: flex;
    align-items: center;
    height: 68px;
    padding: 0 10px;
    border-bottom: 1px solid #e2e3e3;
    & .news-type {
      margin-right: 5px;
    }
    & .news-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }
`;

const ReliefPlaceCard = styled<FC<NewsProps>>(({ keyword, ...props }) => {
  const checkLink = (category: string, index: string) => {
    if (category === BOARD_KIND_PRESS) {
      return `/about-us/press/${index}`;
    }
    return `/what-we-do/news/${index}`;
  };

  const [reliefNews, setReliefNews] = useState<NewsData[]>([]);

  // 구호 현장 키워드 뉴스 로드
  const loadReliefNews = useCallback(async (targetkeyword) => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [
          BOARD_KIND_NEWS,
          BOARD_KIND_PUBLIC,
          BOARD_KIND_STORY,
          BOARD_KIND_FIELDTRIP,
          BOARD_KIND_INTERVIEW,
          BOARD_KIND_PRESS,
        ],
        pagePerCount: 4,
        keyword: targetkeyword,
        keywordRequirement:'tit',
        keywordUnescapeHtml: 'Y',
        postOrderOption: 'Y',
      });
      const articles = data as any;
      setReliefNews(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            link: checkLink(
              article.boardCategoryCode,
              article.boardIndexNumber,
            ),
            boardCategoryCode: article.boardCategoryCode,
            category:
              article.boardCategoryCode &&
              BOARD_CATEGORIES[article.boardCategoryCode],
            date: article.dispStartDate,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date)),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadReliefNews(keyword);
  }, [keyword, loadReliefNews]);
  return (
    <>
      {reliefNews.map((row) => (
        <NewsList key={row.id}>
          <LinkSafe
            to={row.link || ''}
            className="link-item"
            state={{ boardCategoryCode: row.boardCategoryCode }}
          >
            <span className="news-type">[{row.category}] </span>
            <span className="news-title">{row.title}</span>
          </LinkSafe>
        </NewsList>
      ))}
    </>
  );
})``;

export default ReliefPlaceCard;
