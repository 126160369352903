import BaseLayout from './BaseLayout';
import { NullSection } from '@/components/Css/Layout';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  overflow-x: hidden;
  position: relative;

  Header {
    top: 0;
  }

  ${breakpoint(`mobile`)} {
    padding-top: 56px;
  }
`;

const NullSectionContainer = styled(NullSection)`
  ${breakpoint(`mobile`)} {
    display: none;
  }
`;

const MainLayout: FC = ({ children }) => (
  <BaseLayout>
    <Wrapper>
      <Header />
      <NullSectionContainer hideGnb />

      {children}
      <Footer />
    </Wrapper>
  </BaseLayout>
);

export default MainLayout;
