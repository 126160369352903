import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

const NewsletterSection = styled.div`
  width: 100%;

  .inner-content {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .sec-header {
      width: 33.4%;
      padding-bottom: 0;
    }
    .newsletter-input-contents {
      width: 66.6%;
      padding-left: 10px;
    }

    ${breakpoint(`tablet`)} {
      .sec-header,
      .newsletter-input-contents {
        width: 100%;
        padding: 0;
      }

      .sec-header {
        padding-bottom: 40px;
      }
    }
  }

  .form-flex {
    .form-flex-cell {
      & + .form-flex-cell {
        padding-left: 32px;
      }
    }
    .newsletter-cell-name {
      width: 160px;
    }
    .newsletter-cell-email {
      width: 256px;
    }
    ${breakpoint(800)} {
      display: block;
      .form-flex-cell {
        display: block;
        margin-top: 8px;
        button {
          width: 100%;
        }
        & + .form-flex-cell {
          padding: 0;
        }
        &.newsletter-cell-name {
          width: 40%;
          display: inline-block;
        }
        &.newsletter-cell-email {
          width: 60%;
          display: inline-block;
          padding-left: 2%;
        }
      }
    }
  }
  .button-submit-skyblue {
    display: inline-block;
    border: none;
    width: 224px;
    height: 56px;
    background: #1cabe2;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: -1.2px;
    ${breakpoint(`mobile`)} {
      font-size: 16px;
      line-height: 1.63;
      letter-spacing: -0.8px;
      margin-top: 8px;
      width: 100%;
    }
  }
  .private-text {
    font-size: 14px;
    color: #828385;
    margin-top: 10px;
    line-height: 2;
    letter-spacing: -0.8px;
    ${breakpoint(`mobile`)} {
      font-size: 12px;
    }
  }
`;

export default NewsletterSection;
