import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link } from 'gatsby';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperBasic from '../Swiper/SwiperBasic';

interface BoardProps {
  boards: BoardData[];
}

export interface BoardData {
  id: string | number;
  category?: string;
  subject?: string;
  date?: string;
  link: string;
  pcImage: ImgHTMLAttributes<any>['src'];
  mobileImage: ImgHTMLAttributes<any>['src'];
  optData1?: string;
  optData2?: string;
  boardCategoryCode?: string;
}
const SwiperContainer = styled(Swiper)`
  .swiper-wrapper {
    padding-top: 20px;
  }

  .swiper-slide {
    width: 328px;
    & + .swiper-slide {
      margin-left: 40px;
    }
    :first-child {
      margin-left: calc((100% - 1120px) / 2);
    }
    :last-child {
      margin-right: calc((100% - 1120px) / 2);
    }
  }
  ${breakpoint(`mobile`)} {
    .swiper-slide {
      width: 280px;
      & + .swiper-slide {
        margin-left: 16px;
      }
      :first-child {
        margin-left: 0;
      }
      :last-child {
        margin-right: 0;
      }
    }
  }
`;

const BannerImage = styled.div`
  width: 100%;
  height: 384px;
  overflow: hidden;
  position: relative;
  img {
    width: auto;
    height: 100%;
    max-width: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  ${breakpoint(640)} {
    height: 350px;
  }
`;
const ListBannerMessage = styled.div`
  box-sizing: border-box;
  width: 256px;
  border-radius: 0 0 40px 0;
  box-shadow: 24px 14px 40px 0 rgba(0, 0, 0, 0.08);
  background: #fff;
  position: relative;
  padding: 28px 16px;
  margin-top: -88px;
  /* 확대되었을때 왼쪽에 사진이 삐져나와서 보정 */
  margin-left: -1px;
  .ListBanner-card--cg {
    display: inline-block;
    background: #1cabe2;
    padding: 0 24px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 16px;
    position: absolute;
    left: 0;
    top: -20px;
  }
  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #2d2926;
    height: 84px;
    letter-spacing: -0.8px;
    word-break: break-all;
  }
  .ListBanner-card--date {
    position: relative;
    font-size: 16px;
    line-height: 2;
    letter-spacing: -0.8px;
  }
  ${breakpoint(`mobile`)} {
    width: 195px;
    padding: 24px 10px 12px 10px;
    .ListBanner-card--cg {
      padding: 0 24px;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
    }
    p {
      font-size: 14px;
      line-height: 2;
      letter-spacing: -0.8px;
      height: 84px;
    }
    .ListBanner-card--date {
      font-size: 14px;
      padding: 12px 0 0 0;
    }
  }
`;

const ListBanner = styled.div`
  width: 328px;
  transition: transform 0.5s;
  &:hover {
    transform: scale(1.05);
  }
  ${breakpoint(640)} {
    width: 280px;
  }
`;

const BoardLatestCard: FC<BoardProps> = ({ boards }) => (
  <SwiperBasic>
    <SwiperContainer
      slidesPerView="auto"
      navigation
      scrollbar={{ draggable: true }}
    >
      {boards.map((board) => (
        <SwiperSlide key={board.id}>
          <ListBanner>
            <Link
              to={board.link}
              state={{ boardCategoryCode: board.boardCategoryCode }}
            >
              <BannerImage>
                <img src={board.pcImage} alt={board.subject} />
              </BannerImage>
              <ListBannerMessage>
                <span className="ListBanner-card--cg">{board.category}</span>
                <p>
                  {board.subject && board.subject.length > 47
                    ? `${board.subject.substr(0, 47)}...`
                    : board.subject}
                </p>
                <span className="ListBanner-card--date">{board.date}</span>
              </ListBannerMessage>
            </Link>
          </ListBanner>
        </SwiperSlide>
      ))}
    </SwiperContainer>
  </SwiperBasic>
);
export default BoardLatestCard;
